import React, { useState } from "react";
import "./ChangePassword.scss";
import callApi from "../../utils/callApi"; // Assuming you have this utility to call APIs
import { ACCOUNTPATH } from "../../utils/enum"; // Adjust to your API path

const ChangePassword = () => {
  const userId = localStorage.getItem("id"); // Assuming the user ID is stored in localStorage

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate passwords
    if (!oldPassword || !newPassword || !confirmNewPassword) {
      setErrorMessage("Vui lòng điền đầy đủ thông tin.");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Mật khẩu mới không khớp.");
      return;
    }

    // Regex to check if the new password has at least one uppercase letter and one special character
    const passwordPattern = /^(?=.*[A-Z])(?=.*[!@#$%^&*]).+$/;
    if (!passwordPattern.test(newPassword)) {
      setErrorMessage(
        "Mật khẩu mới phải có ít nhất một ký tự viết hoa và một ký tự đặc biệt (!@#$%^&*)."
      );
      return;
    }

    // API call to change the password
    const data = {
      user_id: userId,
      old_password: oldPassword,
      new_password: newPassword,
    };

    try {
      const response = await callApi(
        `${ACCOUNTPATH.CHANGE_PASSWORD}`, // Adjust to your API endpoint
        "POST",
        data
      );

      if (response.status === 200) {
        alert("Đổi mật khẩu thành công!");
      } else {
        setErrorMessage("Đổi mật khẩu thất bại. Vui lòng thử lại.");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      setErrorMessage("Có lỗi xảy ra. Vui lòng thử lại.");
    }
  };

  return (
    <div className="change-password-container">
      <h2 className="change-password-title">Đổi mật khẩu</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="change-password-label">Mật khẩu hiện tại</label>
          <input
            type="password"
            className="change-password-input"
            placeholder="Nhập mật khẩu hiện tại"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </div>
        <div>
          <label className="change-password-label">Mật khẩu mới</label>
          <input
            type="password"
            className="change-password-input"
            placeholder="Nhập mật khẩu mới"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <p className="instruction-text">
            Mật khẩu phải có ít nhất 1 ký tự viết hoa và một ký tự đặc biệt (!@#$%^&*).
          </p>
        </div>
        <div>
          <label className="change-password-label">Nhập lại mật khẩu mới</label>
          <input
            type="password"
            className="change-password-input"
            placeholder="Nhập lại mật khẩu mới"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="button-group">
          <button type="button" className="button-cancel">
            Hủy bỏ
          </button>
          <button type="submit" className="change-password-button">
            Đổi mật khẩu
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
