import React, { useState } from "react";
import "./AccountSettings.scss";
import AccountInfo from "../../components/AccountInfo/AccountInfo";
import Orders from "../../components/Orders/Orders";
import Cards from "../../components/Cards/Cards";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import { useNavigate } from "react-router-dom";

const AccountSettings = () => {
  const [activeTab, setActiveTab] = useState("Thông tin");
  const navigate = useNavigate();

  const renderContent = () => {
    switch (activeTab) {
      case "Thông tin":
        return <AccountInfo />;
      case "Đơn hàng":
        return <Orders />;
      // case "Thẻ":
      //   return <Cards />;
      case "Đổi mật khẩu":
        return <ChangePassword />;
      default:
        return <AccountInfo />;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("id");
    navigate("/dang-nhap");
  };

  return (
    <div className="account-settings">
      <p className="account-settings__title">Tài khoản</p>
      <div className="account-settings__sidebar">
        <h2 className="account-settings__sidebar-title">Cài đặt tài khoản</h2>
        <p className="account-settings__sidebar-description">
        Các thông tin và  các thiết lập cho tài khoản của bạn 
          </p>
        <ul className="account-settings__sidebar-menu">
          {["Thông tin", "Đơn hàng", "Đổi mật khẩu"].map((tab) => (
            <li
              key={tab}
              className={`account-settings__sidebar-item ${
                activeTab === tab ? "active" : ""
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </li>
          ))}
        </ul>
        <hr className="account-settings__divider" />
        <button className="account-settings__logout-btn" onClick={handleLogout}>Đăng xuất</button>
        <hr className="account-settings__divider" />
        <button className="account-settings__delete-btn">Xóa tài khoản</button>
      </div>
      <div className="account-settings__form">{renderContent()}</div>
    </div>
  );
};

export default AccountSettings;
