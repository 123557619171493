import React, { useEffect, useState } from "react";
import "./AccountInfo.scss";
import axios from "axios";
import { ACCOUNTPATH } from "../../utils/enum";
import callApi from "../../utils/callApi";

const AccountInfo = () => {
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedWard, setSelectedWard] = useState("");
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [birthdate, setBirthdate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [address, setAddress] = useState("");
  const [isValid, setIsValid] = useState(false);
  const userId = localStorage.getItem("id");

  useEffect(() => {
    if (userId) {
      const fetchUserDetails = async () => {
        try {
          const response = await callApi(
            `${ACCOUNTPATH.USER_DETAIL}${userId}`,
            "GET"
          );

          if (response.status === 200) {
            const userData = response.data.data;
            console.log(userData)
            setLastname(userData.surname || "");
            setFirstname(userData.name || "");
            setGender(userData.gender || "");
            setPhone(userData.phone || "");
            setEmail(userData.email || "");
            setBirthdate({
              year: userData.birthday?.split("-")[0] || "",
              month: userData.birthday?.split("-")[1] || "",
              day: userData.birthday?.split("-")[2] || "",
            });
            setAddress(userData.address || "");
            setSelectedCity(userData.city || "");
            setSelectedDistrict(userData.district || "");
            setSelectedWard(userData.ward || "");
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      };

      fetchUserDetails();
    }
  }, [userId]);

  useEffect(() => {
    axios
      .get("https://provinces.open-api.vn/api/?depth=1")
      .then((response) => setCities(response.data));
  }, []);

  useEffect(() => {
    if (selectedCity) {
      axios
        .get(`https://provinces.open-api.vn/api/p/${selectedCity}?depth=2`)
        .then((response) => {
          setDistricts(response.data.districts);
          setWards([]); // Clear wards when city changes
        });
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedDistrict) {
      axios
        .get(`https://provinces.open-api.vn/api/d/${selectedDistrict}?depth=2`)
        .then((response) => setWards(response.data.wards));
    }
  }, [selectedDistrict]);

  const handleCityChange = (e) => {
    const cityCode = e.target.value;
    setSelectedCity(cityCode);
  };

  const handleDistrictChange = (e) => {
    const districtCode = e.target.value;
    setSelectedDistrict(districtCode);
  };

  const handleWardChange = (e) => {
    const wardCode = e.target.value;
    setSelectedWard(wardCode);
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setBirthdate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const isFormValid =
      lastname &&
      firstname &&
      gender &&
      phone &&
      email &&
      birthdate.day &&
      birthdate.month &&
      birthdate.year &&
      selectedCity &&
      selectedDistrict &&
      selectedWard &&
      address;
    setIsValid(isFormValid);
  };

  useEffect(() => {
    validateForm();
  }, [
    lastname,
    firstname,
    gender,
    phone,
    email,
    birthdate,
    selectedCity,
    selectedDistrict,
    selectedWard,
    address,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid) {
      const data = {
        surname: lastname,
        name: firstname,
        email: email,
        phone: phone,
        gender: gender,
        city: selectedCity,
        district: selectedDistrict,
        ward: selectedWard,
        address: address,
        birthday: `${birthdate.year}-${birthdate.month}-${birthdate.day}`,
      };

      console.log(data)

      try {

        const response = await callApi(`${ACCOUNTPATH.USER_DETAIL}${userId}`, "PUT", data);

        if (response.status === 200) {
          alert("Thông tin đã được lưu thành công.");
        } else {
          alert("Lưu thông tin thất bại.");
        }
      } catch (error) {
        console.error("Error saving data:", error);
        alert("Có lỗi xảy ra. Vui lòng thử lại.");
      }
    } else {
      alert("Vui lòng điền đầy đủ thông tin.");
    }
  };

  return (
    <div className="account-info">
      <h2 className="account-info__title">Thông tin tài khoản</h2>
      <form className="account-info__form" onSubmit={handleSubmit}>
        <div className="account-info__form-group">
          <label htmlFor="lastname">Họ</label>
          <input
            type="text"
            id="lastname"
            value={lastname}
            onChange={handleInputChange(setLastname)}
            placeholder="Nhập họ"
          />
        </div>
        <div className="account-info__form-group">
          <label htmlFor="firstname">Tên</label>
          <input
            type="text"
            id="firstname"
            value={firstname}
            onChange={handleInputChange(setFirstname)}
            placeholder="Nhập tên"
          />
        </div>
        <div className="account-info__form-group">
          <label htmlFor="gender">Giới tính</label>
          <select
            id="gender"
            value={gender}
            onChange={handleInputChange(setGender)}
          >
            <option value="">Chọn giới tính</option>
            <option value="male">Nam</option>
            <option value="female">Nữ</option>
          </select>
        </div>
        <div className="account-info__form-group">
          <label htmlFor="phone">Số điện thoại</label>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={handleInputChange(setPhone)}
            placeholder="0987 654 321"
          />
        </div>
        <div className="account-info__form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleInputChange(setEmail)}
            placeholder="value@gmail.com"
          />
        </div>
        <div className="account-info__form-group account-info__form-group--date">
          <label>Ngày sinh</label>
          <div className="account-info__date-inputs">
            <input
              type="text"
              name="day"
              value={birthdate.day}
              onChange={handleDateChange}
              placeholder="Ngày"
            />
            <input
              type="text"
              name="month"
              value={birthdate.month}
              onChange={handleDateChange}
              placeholder="Tháng"
            />
            <input
              type="text"
              name="year"
              value={birthdate.year}
              onChange={handleDateChange}
              placeholder="Năm"
            />
          </div>
        </div>
        <div className="account-info__form-group">
          <label htmlFor="city">Thành phố/tỉnh</label>
          <select id="city" onChange={handleCityChange} value={selectedCity}>
            <option>Chọn thành phố/tỉnh</option>
            {cities.map((city) => (
              <option key={city.code} value={city.code}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        <div className="account-info__form-group">
          <label htmlFor="district">Quận/huyện</label>
          <select
            id="district"
            onChange={handleDistrictChange}
            value={selectedDistrict}
          >
            <option>Chọn quận/huyện</option>
            {districts.map((district) => (
              <option key={district.code} value={district.code}>
                {district.name}
              </option>
            ))}
          </select>
        </div>
        <div className="account-info__form-group">
          <label htmlFor="ward">Phường/xã</label>
          <select id="ward" onChange={handleWardChange} value={selectedWard}>
            <option>Chọn phường/xã</option>
            {wards.map((ward) => (
              <option key={ward.code} value={ward.code}>
                {ward.name}
              </option>
            ))}
          </select>
        </div>
        <div className="account-info__form-group">
          <label htmlFor="address">Địa chỉ</label>
          <input
            type="text"
            id="address"
            value={address}
            onChange={handleInputChange(setAddress)}
            placeholder="Nhập địa chỉ"
          />
        </div>
        <div className="account-info__form-group account-info__form-group--checkbox">
          <label htmlFor="agree">
            <input type="checkbox" id="agree" />
            Nhấn "tạo tài khoản" đồng nghĩa với việc bạn đồng ý với{" "}
            <a href="#">Các điều khoản & điều kiện của Là Nhà Decor</a>
          </label>
        </div>
        <div className="account-info__form-actions">
          <button type="button" className="btn btn-secondary">
            Hủy bỏ
          </button>
          <button type="submit" className="btn btn-primary">
            Lưu thông tin
          </button>
        </div>
      </form>
    </div>
  );
};

export default AccountInfo;
