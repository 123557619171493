import React, { useState } from "react";
import "./LoginPage.scss";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/Popup/Popup";
import { ACCOUNTPATH } from "../../utils/enum";
import callApi from "../../utils/callApi";

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    surname: "",
    name: "",
    phone: "",
    email: "",
    password: "",
    password2: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [popup, setPopup] = useState({ show: false, title: "", message: "" });
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!formData.surname) newErrors.surname = "Họ không được để trống.";
    if (!formData.name) newErrors.name = "Tên không được để trống.";
    if (!formData.phone) newErrors.phone = "Số điện thoại không được để trống.";
    if (!formData.email) newErrors.email = "Email không được để trống.";
    if (!formData.password)
      newErrors.password = "Mật khẩu không được để trống.";
    if (formData.password !== formData.password2)
      newErrors.password2 = "Mật khẩu xác nhận không khớp.";
    return newErrors;
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});
    setIsSubmitting(true);

    try {
      const data = {
        surname: formData.surname,
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        password: formData.password,
        role: 1,
      };
      const response = await callApi(ACCOUNTPATH.REGISTER, "POST", data);
      if (response.status === 201) {
        const loginData = {
          username: formData.email,
          password: formData.password,
        };

        const loginResponse = await callApi(
          ACCOUNTPATH.LOGIN,
          "POST",
          loginData
        );

        if (loginResponse.status === 200) {
          localStorage.setItem("authToken", loginResponse.data.data.token);
          localStorage.setItem("id", loginResponse.data.data._id);

          navigate("/thong-tin-ca-nhan");
        } else {
          console.error("Đăng nhập thất bại:", loginResponse.data.message);
          alert("Đăng nhập tự động thất bại. Vui lòng thử đăng nhập thủ công.");
        }
      } else {
        console.error("Đăng ký thất bại:", response.data.message);
        alert("Đăng ký thất bại. Vui lòng thử lại.");
      }
    } catch (error) {
      setPopup({
        show: true,
        title: "Lỗi",
        message: "Có lỗi xảy ra. Vui lòng thử lại sau.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const closePopup = () => {
    setPopup({ ...popup, show: false });
  };

  const navigateToLogin = () => {
    navigate("/dang-nhap");
  };

  return (
    <div className="login-page">
      <h1 className="login-page__title">Đăng ký thành viên</h1>
      <button className="btn btn-back" onClick={navigateToLogin}>
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M11.6665 14.7115L6.95508 10L11.6665 5.28857L12.5447 6.1667L8.71133 10L12.5447 13.8334L11.6665 14.7115Z"
            fill="#121212"
          />
        </svg>{" "}
        Trở về
      </button>
      <div className="login-page__content">
        {/* {popup.show && (
          <Popup
            title={popup.title}
            message={popup.message}
            onClose={closePopup}
            navigateButton={
              popup.title === "Thành công"
                ? { label: "Đi đến Đăng nhập", onClick: navigateToLogin }
                : null
            }
          />
        )} */}

        <div className="login-page__form">
          <h2 className="login-page__form-title">Thông tin tài khoản</h2>
          <p className="login-page__form-subtitle">
            Thông tin tài khoản
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                id="surname"
                placeholder="Họ"
                value={formData.surname}
                onChange={handleInputChange}
              />
              {errors.surname && (
                <span className="error">{errors.surname}</span>
              )}
            </div>
            <div className="form-group">
              <input
                type="text"
                id="name"
                placeholder="Tên"
                value={formData.name}
                onChange={handleInputChange}
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
            <div className="form-group">
              <input
                type="text"
                id="phone"
                placeholder="Điện thoại"
                value={formData.phone}
                onChange={handleInputChange}
              />
              {errors.phone && <span className="error">{errors.phone}</span>}
            </div>
            <div className="form-group">
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <div className="form-group">
              <input
                type="password"
                id="password"
                placeholder="Mật khẩu"
                value={formData.password}
                onChange={handleInputChange}
              />
              {errors.password && (
                <span className="error">{errors.password}</span>
              )}
            </div>
            <div className="form-group">
              <input
                type="password"
                id="password2"
                placeholder="Xác nhận mật khẩu"
                value={formData.password2}
                onChange={handleInputChange}
              />
              {errors.password2 && (
                <span className="error">{errors.password2}</span>
              )}
            </div>
            <p className="login-page__form-description">
              Mật khẩu phải có ít nhất 1 ký tự viết hoa và một ký tự đặc biệt
              !@#$%^&*()
            </p>
            <div className="form-options-register">
              <label>
                <input type="checkbox" /> Tôi chấp nhận{" "}
                <a href="#">chính sách bán hàng & bảo mật</a>.
              </label>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Đang đăng ký..." : "Đăng ký"}
            </button>
          </form>
        </div>

        <div className="login-page__register">
          <h2>Dành riêng cho hội viên</h2>
          <p>
            Trải nghiệm những quyền lợi riêng biệt của Là Nhà Decor Membership
          </p>
          <ul className="login-page__register-benefits">
            <li className="login-page__register-benefit">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.54934 17.6538L4.21484 12.3193L5.28384 11.2501L9.54934 15.5156L18.7148 6.3501L19.7838 7.41935L9.54934 17.6538Z"
                  fill="#121212"
                />
              </svg>{" "}
              Tích điểm cho mỗi đơn hàng
            </li>
            <li className="login-page__register-benefit">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.54934 17.6538L4.21484 12.3193L5.28384 11.2501L9.54934 15.5156L18.7148 6.3501L19.7838 7.41935L9.54934 17.6538Z"
                  fill="#121212"
                />
              </svg>
              Voucher tháng sinh nhật
            </li>
            <li className="login-page__register-benefit">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.54934 17.6538L4.21484 12.3193L5.28384 11.2501L9.54934 15.5156L18.7148 6.3501L19.7838 7.41935L9.54934 17.6538Z"
                  fill="#121212"
                />
              </svg>
              Chương trình khuyến mãi đặc biệt cho Member
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
