import { useDispatch, useSelector } from "react-redux";
import "./FAQPage.scss"
import { useEffect, useState } from "react";
import { getFAQAction } from "../../store/actions/faq.action";
import { POLICYPATH } from "../../utils/enum";
import callApi from "../../utils/callApi";

const FAQPage = () => {
    const dispatch = useDispatch();
    const { dataHeroSesstion, section, loading, error } = useSelector((state) => state.faq);

    const [faqs, setFaqs] = useState([]);
    const [faqError, setFaqError] = useState(null);
    const [faqLoading, setFaqLoading] = useState(false);

    const fetchFaqs = async () => {
        setFaqLoading(true);
        try {
            const response = await callApi(POLICYPATH.FAQ, "GET");
            if (response?.data?.data) {
                setFaqs(response.data.data);
            }
        } catch (err) {
            setFaqError(err.message);
        } finally {
            setFaqLoading(false);
        }
    };

    useEffect(() => {
        dispatch(getFAQAction());
        fetchFaqs();
    }, [dispatch]);

    return (<div className="faq--page">
        {section && dataHeroSesstion[section] && dataHeroSesstion[section] && <section className={section}>
            <img src={dataHeroSesstion[section].attachments[0]} alt="" />
            <div className="layer-blur"></div>
            <div className={section + "--content"}>
                <h1 className={section + "--content--title"} dangerouslySetInnerHTML={{
                    __html: dataHeroSesstion[section].title || "",
                }}></h1>
                <h3 className={section + "--content--content"} dangerouslySetInnerHTML={{
                    __html: dataHeroSesstion[section].content || "",
                }}></h3>
            </div>
        </section>}
        <section className="faq-section">
            <h2 className="faq-title">Các câu hỏi thường gặp</h2>
            {faqs.length > 0 ? (
                <ul className="faq-list">
                    {faqs.map((faq, index) => (
                        <li key={`faq-${index}`} className="faq-item">
                            <h3 className="faq-question">{`${index + 1}. ${faq.question}`}</h3>
                            <p className="faq-answer">{faq.answer}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>Không có câu hỏi nào được tìm thấy.</p>
            )}
        </section>
    </div>);
}

export default FAQPage;