import React from "react";
import "./Popup.scss";

const Popup = ({ title, message, onClose, navigateButton }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="popup-actions">
          {navigateButton && (
            <button
              className="popup-navigate-btn"
              onClick={navigateButton.onClick}
            >
              {navigateButton.label}
            </button>
          )}
          <button className="popup-close-btn" onClick={onClose}>
            Đóng
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
