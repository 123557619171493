import { useEffect, useState } from "react";
import "./Header.scss";

import ICMenu from "../../assets/icons/ICMenu";
import ICSearch from "../../assets/icons/ICSearch";
import ICAccount from "../../assets/icons/ICAccount";
import ICCart from "../../assets/icons/ICCart";
import Logo from "../../assets/icons/Logo";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MenuComponent from "./MenuComponent";
import SearchComponent from "./SearchComponent";
import { useSelector } from "react-redux";
import ModalCart from "../Modal/ModalCart";
import { stringToUrl } from "../../utils/url.utils";

const Header = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const currentUrl = location.pathname;

  const { cartLocal } = useSelector((state) => state.cartLocal);

  const [showModalCart, setShowModalCart] = useState(false);

  const lstUrl = [
    "sofa",
    "san-pham",
    "gio-hang",
    "thanh-toan",
    "trang-thai",
    "tim-kiem",
    "thanh-toan/trang-thai",
    "dang-nhap",
    "khong-tim-thay-trang",
    "thong-tin-ca-nhan",
    "tin-tuc/",
  ];

  const checkUrl = (url) => {
    return lstUrl.some((keyword) => url.includes(keyword));
  };

  const { dataProductCategory } = useSelector(
    (state) => state.dataProductCategory
  );

  const closeModalCart = () => {
    setShowModalCart(false);
  };

  useEffect(() => {
    const subHeader = document.querySelector("header .sub");
    const header = document.querySelector("header");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        subHeader.classList.add("shrink");
        if (window.scrollY > 64) header.classList.add("shrink");
      } else {
        subHeader.classList.remove("shrink");
        header.classList.remove("shrink");
      }
    });
    return () =>
      window.removeEventListener("scroll", () => {
        if (window.scrollY > 50) {
          subHeader.classList.add("shrink");
        } else {
          subHeader.classList.remove("shrink");
        }
      });
  }, []);

  useEffect(() => {
    if (cartLocal.lastItem) {
      setShowModalCart(true);

      const timeout = setTimeout(() => {
        setShowModalCart(false);
      }, 2500);

      return () => clearTimeout(timeout);
    }
  }, [cartLocal.lastItem]);

  const handleButtonClick = () => {
    // Kiểm tra token
    const token = localStorage.getItem("authToken"); 
    console.log(token)
    if (token && token !== "undefined") {
      console.log("thong tin")
      navigate("/thong-tin-ca-nhan");
    } else {
      console.log("đăng nhập")
      navigate("/dang-nhap");
    }
  };

  return (
    <>
      <header
        id="header"
        className={`${checkUrl(currentUrl) && "changeColor"}`}
      >
        <div className="main--menu">
          <div className="main--menu--option-left">
            <div
              className="button"
              onClick={() => {
                const menu = document.getElementById("menu--header");
                if (menu) {
                  menu.classList.remove("shrink");
                }
              }}
            >
              <div className="header--icon">
                <ICMenu />
              </div>
              <span className="button--name">Danh mục</span>
            </div>
            <div
              className="button"
              onClick={() => {
                const searchModal = document.getElementById("search--header");
                const searchInput =
                  document.getElementById("inputSearchHeader");
                if (searchModal) {
                  searchModal.classList.remove("shrink");
                  searchInput.focus();
                }
              }}
            >
              <div className="header--icon">
                <ICSearch />
              </div>
              <span className="button--name">Tìm kiếm</span>
            </div>
          </div>
          {/* <img src={LOGO} alt="" /> */}
          <div onClick={() => navigate("/")} className="header--logo">
            <Logo />
          </div>
          <div className="main--menu--option-right">
            <div className="button" onClick={handleButtonClick}>
              <div className="header--icon">
                <ICAccount />
              </div>
            </div>
            <div className="button" onClick={() => navigate("/gio-hang")}>
              <div className="header--icon">
                <ICCart />

                {cartLocal?.listProduct?.length !== 0 && (
                  <span className="qty-cart">
                    {cartLocal?.listProduct?.length}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="sub">
          <div className="sub--menu--tab-left">
            {
              dataProductCategory?.categoryFilter&&<div
              className={`tab`}
              onClick={(e) => {
                e.stopPropagation();
                const allProduct = dataProductCategory?.categoryFilter?.find(
                  (category) =>
                    category?.title?.toLowerCase().includes("tất cả")
                );
                navigate(`/sofa/${stringToUrl(allProduct?.title)}`);
              }}
            >
              <span
                className={`tab--name hover-underline-animation ${
                  currentUrl.includes("sofa") && "active"
                }`}
              >
                sofa
              </span>
            </div>
            }
            
            {/* <div
              className="tab"
              onClick={(e) => {
                e.stopPropagation();
                window.open("https://designv2.lanha.vn/");
              }}
            >
              <span className="tab--name hover-underline-animation">
                virtual
              </span>
            </div> */}
          </div>
          <div className="sub--menu--tab-right">
            <div className="tab" onClick={()=>navigate(`/tin-tuc`)}>
              <span className="tab--name hover-underline-animation">
                tin tức
              </span>
            </div>
            <div className="tab" onClick={(e) => {
                e.stopPropagation();
                navigate(`/ve-chung-toi`);
              }}>
              <span className="tab--name hover-underline-animation">
                về chúng tôi
              </span>
            </div>
          </div>
        </div>
        <MenuComponent />
        <SearchComponent />
      </header>

      {/* {showModalCart && <ModalCart close={closeModalCart} />} */}
      <section
        className={`modal-cart ${
          showModalCart ? "modal-cart-in" : "modal-cart-out"
        }`}
      >
        <ModalCart close={closeModalCart} />
      </section>
    </>
  );
};

export default Header;
