export const SectionPath = {
  HEADER: "section/webui/header",
  HERO__IIEMS: "section/webui/hero__items",
  CATEGORIES: "section/webui/categories",
  FEATURED_PRODUCTS: "section/webui/featured_products",
  // PRODUCT_GROUP: "section/webui/product_group",
  VIDEO: "section/webui/video",
  KEY_FEATURE: "section/webui/key_feature",
  FEATURE_ITEMS: "section/webui/feature__items",
  // ELECTRIC_SOFA: "section/webui/electric_sofa",
  DISCOUNT: "section/webui/discount",
  DISCOUNT_2: "section/webui/discount_2",
  BEST_SELLING_PRODUCTS: "section/webui/best_selling_products",
  KEY_DETAILS: "section/webui/key_details",
  MECHANICAL_SOFA: "section/webui/mechanical_sofa",
  INTRO_SHOWROOM: "section/webui/intro_showroom",
  // VIRTUAL: "section/webui/virtual",
  INSPIRE: "section/webui/inspire",
  // MORE_ELECTRIC_SOFA: "section/webui/more_electric_sofa",
  // MORE_MECHANICAL_SOFA: "section/webui/more_electric_mechanical",

  PROMOTION_ITEMS: "section/webui/promotion__items",
  INFO_ERGONOMIC: "section/webui/info_ergonomic",
  INFO_ERGONOMIC_ITEMS: "section/webui/info_ergonomic__items",

  HERO_ABOUT_US: "section/webui/hero_about_us",
  ABOUT_US: "section/webui/about_us",
  FEATURE_ABOUT_US: "section/webui/feature_about_us",
  WOOD_WORKSHOP: "section/webui/wood_workshop",
  HERO_POLICY: "section/webui/hero_policy",
  POLICY_ITEM: "section/webui/policy__items",
  HERO_LOCATION: "section/webui/hero_location",
  LOCATIONS: "section/webui/locations",
  HERO_BLOG: "section/webui/hero_blog",
  HERO_FAQ: "section/webui/hero_faq",
};

export const ProductPath = {
  // PROD_ELECTRIC_SOFA: "products?category=2914567750761112",
  // PROD_MECHANICAL_SOFA: "products?category=4114077396888020",
  PROD_BEST_SELL: "products?tag=8006868530672968",
  PROD_FEATURED: "products?tag=6058854523065904",
  PROD_RECENT:"products/recent"
};
export const OrderPath = {
  ORDER: "order",
  ORDER_STATUS: "order/status",
};
export const CategoryId = {
  ELECTRIC_SOFA: "2914567750761112",
  MECHANICAL_SOFA: "4114077396888020",
};

export const Product = {
  PRODUCTS: "products",
  CATEGORIES: "categories",
  FEATURES: "features",
  TAGS: "tags",
};

export const FEATURES = {
  SIMILAR_COLOR: "similar_color",
  MATERIAL: "material",
};

export const TAGS = {
  DESIGN: "design",
  STYLE: "style",
};
export const Payment = {
  MOMO: "payment/momo",
};

export const POLICYPATH = {
  FAQ: "faqs",
};

export const ACCOUNTPATH = {
  REGISTER: "user/signup",
  LOGIN : "user/login",
  USER_DETAIL : "user/detail/",
  CHANGE_PASSWORD : "user/change-password",
  ORDERS : "orders",
  ORDER : "order/",
};
export const Blog = {
  BLOGS: "blogs",
  BLOG: "blog",
}

export const StatusOrder = {
  WAIT_PAYMENT: 0,
  TRANSPORT: 1,
  COMPLETE: 2,
  CANCELED: 3,
  REFUND: 4,
  PAID: 5,
};

export const TypePayment = {
  COD: 0,
  FULL_PAYMENT: 1,
  DEPOSIT: 2,
  INSTALLMENT: 3,
};

export const EmailPeriod = {
  None: 0,
  CONFIRM: 1,
  MANUFACTURE: 2,
  SHIPPING: 3,
  RECEIVED: 4,
}
export const TypeProduct = {
  SOFA:0,
};
