import React, { useEffect, useState } from "react";
import "./Orders.scss";
import OrderDetail from "../OrderDetail/OrderDetail";
import callApi from "../../utils/callApi";
import { ACCOUNTPATH } from "../../utils/enum";

// const orders = [
//   {
//     id: "#082623",
//     status: "Đã xác nhận",
//     statusClass: "confirmed",
//     total: "9,600,000 đ",
//     details: [
//       {
//         product: "Fallyn",
//         quantity: 1,
//         price: "10,800,000 đ",
//         discountedPrice: "9,600,000 đ",
//         image: "/assets/images/home/image404.png", // Thay đường dẫn ảnh phù hợp
//       },
//     ],
//   },
//   {
//     id: "#082623",
//     status: "Giao thành công",
//     statusClass: "delivered",
//     total: "25,200,000 đ",
//     details: [
//       {
//         product: "Fallyn",
//         quantity: 1,
//         price: "10,800,000 đ",
//         discountedPrice: "9,600,000 đ",
//         image: "/assets/images/home/image404.png",
//       },
//       {
//         product: "Praa",
//         quantity: 1,
//         price: "9,600,000 đ",
//         discountedPrice: "9,600,000 đ",
//         image: "/assets/images/home/image404.png",
//       },
//       {
//         product: "Nitro",
//         quantity: 1,
//         price: "6,800,000 đ",
//         discountedPrice: "6,800,000 đ",
//         image: "/assets/images/home/image404.png",
//       },
//     ],
//   },
// ];

const Orders = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [orders, setOrders] = useState(null);
  const userId = localStorage.getItem("id");
  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    if (userId) {
      const fetchUserDetails = async () => {
        try {
          const headers = {
            authorization: `Bearer ${authToken}`,
          };
          const response = await callApi(
            `${ACCOUNTPATH.ORDERS}?owner=${userId}`,
            "GET",
            null,
            headers
          );

          if (response.status === 200) {
            const userData = response.data.data;
            console.log(userData);
            setOrders(userData);
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      };

      fetchUserDetails();
    }
  }, [userId]);

  return (
    <div className="orders">
      {!selectedProduct ? ( // Chỉ hiển thị Orders khi không có sản phẩm được chọn
        <>
          <h1>Đơn hàng</h1>
          <p>Danh sách các đơn hàng của bạn ở ngay đây</p>
          {orders?.map((order) => (
            <div className="order" key={order.id}>
              <div className="order-header">
                <span className="order-id">{order._id}</span>
                <span className={`order-status ${order.statusClass}`}>
                  {order.status}
                </span>
              </div>
              <div className="order-details">
                <table>
                  <thead>
                    <tr>
                      <th>SẢN PHẨM</th>
                      <th>SỐ LƯỢNG</th>
                      <th>GIÁ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.items.map((item, index) => (
                      <tr key={index}>
                        <td className="product">
                          <img src={item.img} alt={item.product} />
                          <span>{item.product_name}</span>
                        </td>
                        <td>{item.quantity.toString().padStart(2, "0")}</td>
                        <td>
                          <div className="price">
                            <span className="original">{item.price_original}</span>
                            <span className="discounted">
                              {item.price_after}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="order-footer">
                <div className="group-price">
                  <span className="total-price">Tổng giá</span>
                  <span className="total-price">{order.amount}</span>
                </div>
                <div className="group-btn">
                  <button
                    className="btn"
                    onClick={() => setSelectedProduct(order)}
                  >
                    Xem chi tiết
                  </button>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        // Hiển thị OrderDetail khi selectedProduct có giá trị
        <OrderDetail
          orderId={selectedProduct._id}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </div>
  );
};

export default Orders;
