import axios from "axios";
import { logOut } from "../pages/Login/LoginPage";

export default async function callApi(
  endpoint,
  method = "GET",
  data,
  headers,
  params,
  signal
) {
  
  try {
    return await axios({
      method: method,
      url: `${process.env.REACT_APP_API_URL}${endpoint}`,
      data: data,
      headers: headers,
      params: params,
      signal:signal
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        logOut();
      }
      throw error.response.data;
    } else if (error.request) {
    } else {
    }
    throw error
  }
}
