import React, { useEffect, useState } from "react";
import "./OrderDetail.scss";
import { Steps, Timeline } from "antd";
import callApi from "../../utils/callApi";
import { ACCOUNTPATH } from "../../utils/enum";

const OrderDetail = ({ orderId, onClose }) => {
  const order = {
    orderId: "082623",
    status: "Đang sản xuất",
    timeline: [
      { step: "Xác nhận đơn hàng", date: "05/01/2025", active: true },
      { step: "Sản xuất", date: "07/01/2025", active: true },
      { step: "Giao hàng", date: "", active: false },
      { step: "Giao thành công", date: "", active: false },
    ],
    customerInfo: {
      name: "Ly Nguyễn",
      phone: "0932737428",
      address:
        "Số 45, Đường Lê Lợi, Phường Bến Nghé, Quận 1, Thành phố Hồ Chí Minh",
    },
    paymentInfo: {
      method: "Visa",
      cardNumber: "********0223",
    },
    products: [
      {
        name: "Fallyn",
        quantity: 1,
        price: 9600000,
        originalPrice: 10800000,
        image: "/assets/images/home/image404.png",
      },
      {
        name: "Praa",
        quantity: 1,
        price: 9600000,
        originalPrice: 9600000,
        image: "/assets/images/home/image404.png",
      },
      {
        name: "Nitro",
        quantity: 1,
        price: 6800000,
        originalPrice: 6800000,
        image: "/assets/images/home/image404.png",
      },
    ],
    total: {
      subTotal: 26000000,
      shipping: 0,
      discount: 800000,
      voucher: "LNDC22Hy4",
      finalPrice: 25200000,
    },
  };

  const stepsData = [
    { title: "Xác nhận đơn hàng", date: "05/01/2025", status: "finish" },
    { title: "Sản xuất", date: "07/01/2025", status: "process" },
    { title: "Giao hàng", date: null, status: "wait" },
    { title: "Giao thành công", date: null, status: "wait" },
  ];

  const { Step } = Steps;

  const [product, setProduct] = useState(null);

  const fetchOrderDetail = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      const headers = {
        authorization: `Bearer ${authToken}`,
      };
      const response = await callApi(
        `${ACCOUNTPATH.ORDER}${orderId}`,
        "GET",
        null,
        headers
      );
      console.log(response.data.data)
      setProduct(response.data.data);
    } catch (error) {
      console.error("Lỗi khi gọi API:", error);
    } finally {
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetail();
    }
  }, [orderId]);

  return (
    <div className="order-detail-wrapper">
      <div className="order-detail">
        <section className="order-section">
          <div className="group-back">
            <button className="back-button" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M13.9995 17.6537L8.3457 11.9999L13.9995 6.34619L15.0532 7.39994L10.4532 11.9999L15.0532 16.5999L13.9995 17.6537Z"
                  fill="#121212"
                />
              </svg>
            </button>
            <h1 className="order-id">#{product?._id}</h1>
          </div>

          <span className="order-status">{order.status}</span>
        </section>

        <section className="timeline">
          <Steps current={1} labelPlacement="vertical">
            {stepsData.map((step, index) => (
              <Step
                key={index}
                title={step.title}
                // description={step.date || ""}
                status={step.status}
              />
            ))}
          </Steps>
        </section>

        <section className="details">
          <div className="info-block">
            <h2>Thông Tin Nhận Hàng</h2>
            <p>
              <strong>Người nhận:</strong> {product?.name_owner}
            </p>
            <p>
              <strong>Điện thoại:</strong> {product?.phone}
            </p>
            <p>
              <strong>Địa chỉ nhận hàng:</strong> {product?.address}
            </p>
          </div>
          <div className="info-block">
            <h2>Thanh Toán</h2>
            <p>
              <strong>Phương thức thanh toán:</strong>{" "}
              {order.paymentInfo.method}
            </p>
            <p>
              <strong>Số thẻ:</strong> {order.paymentInfo.cardNumber}
            </p>
          </div>
        </section>

        <section className="product-list">
          <table>
            <thead>
              <tr>
                <th>SẢN PHẨM</th>
                <th>SỐ LƯỢNG</th>
                <th>GIÁ</th>
              </tr>
            </thead>
            <tbody>
              {product?.items.map((item, index) => (
                <tr key={index}>
                  <td className="item-info">
                    <img src={item.img} alt={item.product_name} />
                    <span>{item.name}</span>
                  </td>
                  <td>{item.quantity}</td>
                  <td>
                    <div className="price">
                      <span className="original-price">
                        {item.price_original.toLocaleString()} đ
                      </span>
                      <span className="discount-price">
                        {item.price_after.toLocaleString()} đ
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <section className="total">
          <div className="group-text">
            <p>
              <strong>Tổng tiền:</strong>{" "}
              {product?.amount.toLocaleString()} đ
            </p>
            <p>
              <strong>Phí vận chuyển:</strong>{" "}
              {product?.shipping_fee.toLocaleString()} đ
            </p>
            <p>
              <strong>Giảm giá:</strong> {product?.discount.toLocaleString()}{" "}
              đ
            </p>
            <p>
              <strong>Mã voucher:</strong> {product?.voucher}
            </p>
          </div>
          <h3>
            <strong>Tổng giá:</strong> {product?.amount.toLocaleString()}{" "}
            đ
          </h3>
        </section>
      </div>
    </div>
  );
};

export default OrderDetail;
