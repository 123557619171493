import React, { useState } from "react";
import "./LoginPage.scss";
import { useNavigate } from "react-router-dom";
import { ACCOUNTPATH } from "../../utils/enum";
import callApi from "../../utils/callApi";


export const logOut = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("id");
  window.location.href("/dang-nhap");
};

const LoginPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const data = {
        username: email,
        password: password,
      };
      const loginResponse = await callApi(ACCOUNTPATH.LOGIN, "POST", data);

      if (loginResponse.status === 200) {
        localStorage.setItem("authToken", loginResponse.data.data.token);
        localStorage.setItem("id", loginResponse.data.data._id);

        navigate("/thong-tin-ca-nhan");
      } else {
        const errorData = await loginResponse.json();
        throw new Error(errorData.message || "Đăng nhập thất bại");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="login-page">
      <h1 className="login-page__title">Tài khoản</h1>
      <div className="login-page__content">
        <div className="login-page__form">
          <h2 className="login-page__form-title">Đăng nhập tài khoản</h2>
          <p className="login-page__form-description">
            Đăng nhập để truy cập tài khoản của bạn
          </p>
          <form onSubmit={handleSubmit}>
            {error && <p className="error-message">{error}</p>}

            <div className="form-group">
              <input
                type="email"
                id="email"
                placeholder="Nhập địa chỉ email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                id="password"
                placeholder="Nhập mật khẩu"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-options">
              <label>
                <input type="checkbox" /> Lưu đăng nhập
              </label>
              <a href="#">Quên mật khẩu?</a>
            </div>
            <p className="login-page__terms">
              Nhấn "Đăng nhập" đồng nghĩa với việc bạn đồng ý tuân theo{" "}
              <a href="#">Các điều khoản & điều kiện của Là Nhà Decor</a>
            </p>
            <button type="submit" className="btn btn-primary">
              Đăng nhập
            </button>
            <div className="group-navigate-register">
              <p className="register-text">Bạn chưa có tài khoản?</p>
              <button
                className="register-button"
                onClick={() => navigate("/dang-ky")}
              >
                Tạo tài khoản
              </button>
            </div>
          </form>
        </div>

        <div className="login-page__register">
          <h2>Đăng ký thành viên</h2>
          <p>
            Trải nghiệm những quyền lợi riêng biệt của Là Nhà Decor Membership
          </p>
          <ul className="login-page__register-benefits">
            <li className="login-page__register-benefit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.54934 17.6538L4.21484 12.3193L5.28384 11.2501L9.54934 15.5156L18.7148 6.3501L19.7838 7.41935L9.54934 17.6538Z"
                  fill="#121212"
                />
              </svg>
              Tích điểm cho mỗi đơn hàng
            </li>
            <li className="login-page__register-benefit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.54934 17.6538L4.21484 12.3193L5.28384 11.2501L9.54934 15.5156L18.7148 6.3501L19.7838 7.41935L9.54934 17.6538Z"
                  fill="#121212"
                />
              </svg>
              Voucher tháng sinh nhật
            </li>
            <li className="login-page__register-benefit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9.54934 17.6538L4.21484 12.3193L5.28384 11.2501L9.54934 15.5156L18.7148 6.3501L19.7838 7.41935L9.54934 17.6538Z"
                  fill="#121212"
                />
              </svg>
              Chương trình khuyến mãi đặc biệt cho Member
            </li>
          </ul>
          <button
            className="btn btn-secondary"
            onClick={() => navigate("/dang-ky")}
          >
            Tạo tài khoản
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
